import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <style>
        {`
          .privacy-content {
            font-family: sans-serif;
            text-align: left;
            font-size: 13px; /* Consistent font size with Terms of Service */
          }
          .privacy-content h2 {
            font-size: 18px;
          }
          .privacy-content h3 {
            font-size: 16px;
            margin-top: 20px;
          }
        `}
      </style>
      <div className="privacy-content">
        Updated: January 1, 2025
        <p>Your privacy is important to us. This Privacy Policy explains how ComicLLM.com ("the Site") collects, uses, and protects your information.</p>
        
        <h3>1. Information We Collect</h3>
        <p><strong>Personal Information:</strong> We may collect your email address if you create an account or contact us.</p>
        <p><strong>Usage Data:</strong> We collect information about your use of the Site, such as page views and time spent.</p>
        <p><strong>Prompt Data:</strong> We store the text prompts you submit along with other metadata for each comic created.</p>
        
        <h3>2. How We Use Your Information</h3>
        <p>To provide and improve our services.</p>
        <p>To let you use prompts that you have created previously.</p>
        <p>To communicate with you about updates or support.</p>
        <p>To analyze usage trends and improve the Site.</p>
        
        <h3>3. Cookies</h3>
        <p>The Site uses cookies to enhance your experience. You can disable cookies in your browser settings, but some features may not work as intended.</p>
        
        <h3>4. Sharing Your Information</h3>
        <p>We do not sell your information. We may share it with service providers as needed to operate the Site or if required by law.</p>
        
        <h3>5. Data Security</h3>
        <p>We use reasonable measures to protect your information, but we cannot guarantee absolute security.</p>
        
        <h3>6. Children's Privacy</h3>
        <p>The Site is not intended for children under 13. We do not knowingly collect personal information from children under 13.</p>
        
        <h3>7. Your Rights</h3>
        <p>You can request to access, update, or delete your personal information by contacting us at support@comicllm.com.</p>
        
        <h3>8. Changes to this Policy</h3>
        <p>We may update this Privacy Policy at any time. Continued use of the Site after changes are made constitutes acceptance of the updated Policy.</p>
        
        <h3>9. Contact Us</h3>
        <p>For questions about this Privacy Policy, contact us at support@comicllm.com.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy; 