import axios from 'axios';


const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5002';
//console.log('Final API_URL:', API_URL);

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const generateNovel = async (storyIdea, style, model, panelCount, isPrivate, language) => {
  try {
    const response = await api.post('/api/generate', {
      storyIdea,
      style,
      model,
      panelCount,
      isPrivate,
      language
    });
    return response.data;
  } catch (error) {
    throw (error.response && error.response.data) || {
      error: 'Unknown error',
      message: error.message
    };
  }
};

export const getImageUrl = (imagePath) => {
  return `${API_URL}${imagePath}`;
};

export async function getRecentComics() {
  const response = await api.get('/api/recent-comics');
  return response.data;
}

// Export the Axios instance as the default export
export default api; 