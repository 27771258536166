import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import './EditComic.css';

function EditComic() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [comic, setComic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchComic = async () => {
      try {
        const response = await api.get(`/api/comics/${id}`);
        setComic(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load comic');
        setLoading(false);
      }
    };

    fetchComic();
  }, [id]);

  const handleTitleChange = (newTitle) => {
    setComic({ ...comic, title: newTitle });
  };

  const handleTextChange = (index, newText) => {
    const updatedPanels = [...comic.content];
    updatedPanels[index].dialogue = newText;
    setComic({ ...comic, content: updatedPanels });
  };

  const handleSave = async () => {
    if (saving) return;
    try {
      setSaving(true);
      await api.post(`/api/comics/${id}/save-as-new`, {
        panels: comic.content,
        newTitle: comic.title
      });
      navigate('/my-comics');
    } catch (err) {
      console.error('Save error:', err);
      alert('Failed to save comic. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    navigate('/my-comics');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="edit-comic">
      <h2>Edit Comic</h2>
      <div className="edit-title-field">
        <label htmlFor="title">Edit Title:</label>
        <input
          id="title"
          type="text"
          value={comic.title || ''}
          onChange={(e) => handleTitleChange(e.target.value)}
        />
      </div>
      {comic.content.map((panel, index) => (
        <div key={index} className="panel">
          <img
            src={panel.imageUrl}
            alt={`Panel ${index + 1}`}
            className="panel-image"
          />
          <div className="panel-text-editor">
            <textarea
              value={panel.dialogue || ''}
              onChange={(e) => handleTextChange(index, e.target.value)}
            />
          </div>
        </div>
      ))}
      <div className="buttons-row">
        <button 
          onClick={handleCancel}
          disabled={saving}
        >
          Cancel
        </button>
        <button 
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save as new'}
        </button>
      </div>
    </div>
  );
}

export default EditComic; 