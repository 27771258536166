import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { generateNovel, getRecentComics } from '../services/api';
import ImageOverlay from './ImageOverlay';
import { AuthContext } from '../contexts/AuthContext';
import LoginPromptOverlay from './LoginPromptOverlay';
import './StoryForm.css';
import LoginForm from './LoginForm';
import SignUpOverlay from './SignUpOverlay';

const StoryForm = ({ onNovelGenerated, isLoading, setIsLoading }) => {
  const { user } = useContext(AuthContext);
  const [storyIdea, setStoryIdea] = useState('');
  const [style, setStyle] = useState('comic');
  const [customStyle, setCustomStyle] = useState('');
  const [model, setModel] = useState('flux');
  const [panelCount, setPanelCount] = useState(4);
  const [isPrivate, setIsPrivate] = useState('No');
  const [error, setError] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [fullComicUrl, setFullComicUrl] = useState('');
  const [novelGenerated, setNovelGenerated] = useState(false);
  const [showSampleComic, setShowSampleComic] = useState(true);
  const [recentComics, setRecentComics] = useState([]);
  const [showRecentComics, setShowRecentComics] = useState(true);

  const [selectedRecentComicUrl, setSelectedRecentComicUrl] = useState('');
  const [showRecentOverlay, setShowRecentOverlay] = useState(false);

  const [generatedNovel, setGeneratedNovel] = useState(null);
  const [isDownloadingZip, setIsDownloadingZip] = useState(false);

  const [loginPromptReason, setLoginPromptReason] = useState(null);

  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showSignupPrompt, setShowSignupPrompt] = useState(false);

  const [language, setLanguage] = useState("english");

  const isLoggedIn = Boolean(user);

  useEffect(() => {
    const fetchRecentComics = async () => {
      try {
        const comics = await getRecentComics();
        setRecentComics(comics);
      } catch (err) {
        console.error('Error fetching recent comics:', err);
      }
    };
    fetchRecentComics();
  }, []);

  const handleLikeToggle = async (comicId) => {
    if (!user) {
      setLoginPromptReason('like');
      return;
    }
    try {
      const response = await api.post(`/api/comics/${comicId}/like`);
      const data = response.data;

      setRecentComics((prev) =>
        prev.map((c) =>
          c._id === comicId
            ? { ...c, numLikes: data.numLikes, alreadyLiked: !c.alreadyLiked }
            : c
        )
      );
    } catch (err) {
      console.error('Failed to toggle like:', err);
    }
  };

  const handleRecentComicClick = (comicUrl) => {
    setSelectedRecentComicUrl(comicUrl);
    setShowRecentOverlay(true);
  };

  const handleCloseRecentOverlay = () => {
    setShowRecentOverlay(false);
    setSelectedRecentComicUrl('');
  };

  const handleModelChange = (e) => {
    const newModel = e.target.value;
    if (newModel === 'fluxpro' && !user) {
      setLoginPromptReason('fluxPro');
      return;
    }
    setModel(newModel);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!storyIdea.trim()) {
      setError({
        title: 'Story idea required',
        description: 'Please enter a story idea'
      });
      return;
    }
    
    const selectedStyle = style === 'custom' ? customStyle : style;

    setIsLoading(true);
    setShowSampleComic(false);
    setShowRecentComics(false);
    try {
      const novel = await generateNovel(storyIdea, selectedStyle, model, panelCount, isPrivate === 'Yes', language);
      //console.log('Generated novel object from server:', novel);
      onNovelGenerated(novel);
      setFullComicUrl(novel.combinedImage);
      setNovelGenerated(true);
      setGeneratedNovel(novel);
    } catch (error) {
      setError({
        title: error.error || 'Error',
        description: error.message || 'Failed to generate story'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewFullComic = () => {
    //console.log('Full comic URL:', fullComicUrl);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleDownloadPanels = async () => {
    if (!generatedNovel?._id) {
      console.error('No novel found to download panels.');
      return;
    }
    try {
      setIsDownloadingZip(true);
      const response = await api.get(`/api/comics/${generatedNovel._id}/download-panels`, {
        responseType: 'blob',
      });
      const fileUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `comic.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading panels:', error);
    } finally {
      setIsDownloadingZip(false);
    }
  };

  const handleShowLogin = () => {
    //console.log('Opening login overlay');
    setLoginPromptReason(null);
    setShowLoginPrompt(true);
  };

  const handleShowSignup = () => {
    //console.log('Opening signup overlay');
    setLoginPromptReason(null);
    setShowSignupPrompt(true);
  };

  return (
    <div className="story-form-container">
      {user && (
        <h3>Welcome {user.username}</h3>
      )}
      <form onSubmit={handleSubmit} className="story-form">
        <div className="form-group">
          <textarea
            id="storyIdea"
            value={storyIdea}
            onChange={(e) => setStoryIdea(e.target.value)}
            placeholder="Enter your story idea..."
            rows="4"
            className="story-idea-textarea"
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="style">Style</label>
            <select
              id="style"
              value={style}
              onChange={(e) => setStyle(e.target.value)}
            >
              <option value="comic">Comic Book</option>
              <option value="manga">Manga</option>
              <option value="noir">Noir</option>
              <option value="watercolor">Watercolor</option>
              <option value="children">Children's Book</option>
              <option value="sitcom">Sitcom</option>
              <option value="wimmelbilder">Wimmelbilder</option>
              <option value="film">Feature Film</option>
              <option value="soviet">Soviet propaganda poster</option>
              <option value="editorial">Political Editorial Cartoon</option>
              <option value="newyorker">New Yorker Cartoon</option>
              <option value="farside">Far Side Cartoon</option>
              <option value="storyboard">Storyboard</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="model">Model</label>
            <select
              id="model"
              value={model}
              onChange={handleModelChange}
            >
              <option value="flux">FLUX-schnell</option>
              <option value="sdxl">SDXL-lightning</option>
              <option value="fluxpro">FLUX-pro</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="panelCount">Panels</label>
            <select
              id="panelCount"
              value={panelCount}
              onChange={(e) => setPanelCount(Number(e.target.value))}
            >
              <option value={1}>1 Panel</option>
              <option value={2}>2 Panels</option>
              <option value={4}>4 Panels</option>
              <option value={8}>8 Panels</option>
              <option value={16}>16 Panels</option>
            </select>
          </div>

          {user && (
            <div className="form-group">
              <label htmlFor="isPrivate">Private</label>
              <select
                id="isPrivate"
                value={isPrivate}
                onChange={(e) => setIsPrivate(e.target.value)}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          )}
        </div>

        {style === 'custom' && (
          <div className="form-group">
            <input
              type="text"
              id="customStyle"
              value={customStyle}
              onChange={(e) => setCustomStyle(e.target.value)}
              placeholder="Enter your custom style..."
            />
          </div>
        )}

        <div style={{ marginBottom: '1rem' }}>
          <label htmlFor="language" style={{ marginRight: '10px' }}>Language</label>
          <select
            id="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="english">English</option>
            <option value="arabic">Arabic</option>
            <option value="chinese">Chinese</option>
            <option value="danish">Danish</option>
            <option value="dutch">Dutch</option>
            <option value="fijian">Fijian</option>
            <option value="filipino">Filipino</option>
            <option value="french">French</option>
            <option value="german">German</option>
            <option value="greek">Greek</option>
            <option value="hausa">Hausa</option>
            <option value="hebrew">Hebrew</option>
            <option value="hindi">Hindi</option>
            <option value="igbo">Igbo</option>
            <option value="indonesian">Indonesian</option>
            <option value="italian">Italian</option>
            <option value="japanese">Japanese</option>
            <option value="korean">Korean</option>
            <option value="norwegian">Norwegian</option>
            <option value="persian">Persian</option>
            <option value="polish">Polish</option>
            <option value="portuguese">Portuguese</option>
            <option value="russian">Russian</option>
            <option value="spanish">Spanish</option>
            <option value="swahili">Swahili</option>
            <option value="swedish">Swedish</option>
            <option value="tok pisin">Tok Pisin</option>
            <option value="turkish">Turkish</option>
            <option value="zulu">Zulu</option>
          </select>
        </div>

        <button
          type="submit"
          className="generate-button"
          disabled={isLoading}
        >
          Generate Comic
        </button>
      </form>

      {error && (
        <div className="error-message">
          <h2>{error.title}</h2>
          <p>{error.description}</p>
        </div>
      )}

      {showSampleComic && !novelGenerated && (
        <div className="sample-comic">
          <img 
            src="https://storage.googleapis.com/elancode-storygallery/story_1734056305140.png" 
            alt="Sample Comic" 
            style={{ maxWidth: '800px', width: '100%', height: 'auto', display: 'block', margin: '20px auto' }}
          />
        </div>
      )}

      {showRecentComics && (
        <div className="recent-comics-section" style={{ marginTop: '40px' }}>
          <h3>Recent Comics</h3>
          {recentComics.length === 0 ? (
            <p>No recent comics found.</p>
          ) : (
            <div className="gallery-grid">
              {recentComics.map((comic) => (
                <div
                  className="gallery-item"
                  key={comic._id}
                  style={{ visibility: 'visible' }}
                  onClick={() => handleRecentComicClick(comic.imageUrl || comic.thumbnailUrl)}
                >
                  <span className="poster-name">{comic.userId?.username || 'anon'}</span>
                  <img
                    src={comic.thumbnailUrl || comic.imageUrl}
                    alt={comic.title}
                  />
                  <div
                    className="like-container"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      className="like-icon"
                      src={comic.alreadyLiked ? '/liked.png' : '/unliked.png'}
                      alt={comic.alreadyLiked ? 'Liked' : 'Unliked'}
                      onClick={() => handleLikeToggle(comic._id)}
                    />
                    {comic.numLikes > 0 && (
                      <span className="like-count">
                        {comic.numLikes} {comic.numLikes === 1 ? 'like' : 'likes'}
                      </span>
                    )}
                  </div>
                </div>
              ))}
              <div className="see-all-link-container">
                <h3><a href="/gallery" className="see-all-link">See more</a></h3>
              </div>
            </div>
          )}
        </div>
      )}

      {loginPromptReason && (
        <LoginPromptOverlay 
          reason={loginPromptReason} 
          onClose={() => setLoginPromptReason(null)}
          onLogin={handleShowLogin}
          onSignup={handleShowSignup}
        />
      )}

      {showLoginPrompt && (
        <LoginForm
          isVisible={showLoginPrompt}
          onClose={() => setShowLoginPrompt(false)}
          onSwitchToSignUp={() => {
            setShowLoginPrompt(false);
            setShowSignupPrompt(true);
          }}
        />
      )}
      {showSignupPrompt && (
        <SignUpOverlay
          isVisible={showSignupPrompt}
          onClose={() => setShowSignupPrompt(false)}
          onSwitchToLogin={() => {
            setShowSignupPrompt(false);
            setShowLoginPrompt(true);
          }}
        />
      )}

      {/* Overlay for viewing full recent comics */}
      {showRecentOverlay && (
        <ImageOverlay
          imageUrl={selectedRecentComicUrl}
          onClose={handleCloseRecentOverlay}
        />
      )}

      {novelGenerated && !isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
          <button 
            onClick={handleViewFullComic}
            style={{
              padding: '10px 20px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            View Full Comic
          </button>
          <button
            onClick={handleDownloadPanels}
            style={{
              padding: '10px 20px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Download Panels
          </button>
        </div>
      )}

      {showOverlay && (
        <ImageOverlay imageUrl={fullComicUrl} onClose={handleCloseOverlay} />
      )}

      {isDownloadingZip && (
        <div style={{
          position: 'fixed',
          top: 0, left: 0, right: 0, bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          zIndex: 9999
        }}>
          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center'
          }}>
            <div className="spinner" style={{
              width: '40px',
              height: '40px',
              margin: '0 auto 10px',
              border: '6px solid #ccc',
              borderTop: '6px solid #007BFF',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite'
            }} />
            <p>Preparing your ZIP file...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryForm; 