import React from 'react';

function TermsOfService() {
  return (
    <div>
      <style>
        {`
          .terms-content {
            font-family: sans-serif;
            text-align: left;
            font-size: 13px;
          }
          .terms-content h2 {
            font-size: 18px;
          }
          .terms-content h3 {
            font-size: 16px;
            margin-top: 20px;
          }
        `}
      </style>
      <div className="terms-content">
        <h2>Terms of Service</h2>
        Updated: January 1, 2025
        <p>Welcome to ComicLLM.com ("the Site"). By accessing or using the Site, you agree to the following Terms of Service ("Terms"). If you do not agree, you may not use the Site.</p>
        
        <h3>1. Eligibility</h3>
        <p>You must be at least 13 years old to use the Site. By using the Site, you represent that you meet this requirement.</p>
        
        <h3>2. Use of the Site</h3>
        <p>You may use the Site to create and download AI-generated comics for personal and non-commercial purposes. You agree not to use the Site for illegal or harmful activities.</p>
        
        <h3>3. Prompt Submission</h3>
        <p>You may enter text prompts to generate AI-powered comics. You agree not to submit prompts that are illegal, harmful, or violate the rights of others, including but not limited to copyright, trademark, or intellectual property rights. By submitting prompts, you represent and warrant that your input does not infringe on the intellectual property rights of any third party. ComicLLM reserves the right to suspend or terminate accounts that violate this provision.</p>
        
        <h3>4. Ownership</h3>
        <p>The AI-generated comics you create are owned by you. However, ComicLLM does not own or claim ownership of the AI models used to generate the comics, as these are provided by third-party services. ComicLLM does own the proprietary technology and systems that facilitate the creation of these comics on the Site.</p>
        
        <h3>5. Use of Generated Content</h3>
        <p>By default, AI-generated comics created on the Site may be displayed in the public gallery for community engagement and marketing purposes. By creating AI-generated comics on the Site, you grant ComicLLM a non-exclusive, worldwide, royalty-free license to use, reproduce, and display the comics for marketing and promotional purposes. You retain ownership of the comics, and this license does not limit your rights to use or share them. If you mark a comic as private, it will not be displayed publicly or used for marketing.</p>
        
        <h3>6. Disclaimer of Warranties</h3>
        <p>The Site is provided "as is" without warranties of any kind. ComicLLM does not guarantee that the Site will be error-free or uninterrupted.</p>
        
        <h3>7. Limitation of Liability</h3>
        <p>ComicLLM is not liable for any damages resulting from your use of the Site.</p>
        
        <h3>8. Termination</h3>
        <p>ComicLLM reserves the right to terminate or suspend your access to the Site at any time, for any reason.</p>
        
        <h3>9. Changes to the Terms</h3>
        <p>ComicLLM may update these Terms at any time. Continued use of the Site after changes are made constitutes acceptance of the updated Terms.</p>
        
        <h3>10. Intellectual Property Violations</h3>
        <p>If you create comics using prompts that infringe on the intellectual property of others (e.g., copyrighted characters or trademarks), you assume full responsibility for the content generated. ComicLLM disclaims any liability for content created by users and will take reasonable action, including removing infringing content, upon receiving a valid complaint.</p>
        
        <h3>11. Governing Law</h3>
        <p>These Terms are governed by the laws of the United States and the State of Delaware, without regard to conflict of law principles.</p>
        
        <h3>12. Contact Us</h3>
        <p>For questions about these Terms, contact us at support@comicllm.com.</p>
      </div>
      {/* Add more content as needed */}
    </div>
  );
}

export default TermsOfService; 