import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import api from '../services/api';
import './LoginForm.css';

function LoginForm({ isVisible, onClose, onSwitchToSignUp }) {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/login', { email, password });
      const { token } = response.data;

      // Update authentication state using the login function
      login(token);

      // Clear form fields
      setEmail('');
      setPassword('');
      setError('');

      // Close the login form/modal if applicable
      if (onClose) {
        onClose();
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Invalid email or password.');
    }
  };

  const handleClose = () => {
    setError('');
    if (onClose) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <div className={`overlay ${isVisible ? 'visible' : ''}`}>
      <div className="overlay-content">
        <button onClick={handleClose} className="close-button">&times;</button>
        <form onSubmit={handleSubmit} className="sign-up-form">
          <h2>Login</h2>
          {error && <div className="error-message">{error}</div>}
          <label>
            Email
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <div className="button-footer-container" style={{ textAlign: 'left', margin: '4px 0 0 0' }}>
            <div className="form-footer">
              <button
                type="button"
                className="switch-form-button"
                onClick={(e) => {
                  e.preventDefault();
                  if (email) {
                    // Call API to initiate password reset
                    api.post('/api/request-password-reset', { email })
                      .then(() => setError('Password reset link sent to your email.'))
                      .catch(err => setError(err.response?.data?.error || 'Error sending reset link.'));
                  } else {
                    setError('Please enter your email to reset password.');
                  }
                }}
              >
                Forgot Password?
              </button>

              <button 
                onClick={(e) => {
                  e.preventDefault();
                  onSwitchToSignUp();
                }} 
                className="switch-form-button"
              >
                Need an account? Sign up
              </button>
            </div>
            <button type="submit" className="submit-button">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm; 