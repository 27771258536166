import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import ChangePasswordOverlay from './ChangePasswordOverlay';
import './AccountMenu.css';

function AccountMenu({ onSignUpClick, onLoginClick }) {
  const { user, logout } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const menuRef = useRef(null);

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //console.log('onSignUpClick prop in AccountMenu:', onSignUpClick);

  return (
    <div className="account-menu" ref={menuRef}>
      <div onClick={() => setMenuOpen(!menuOpen)} className="user-icon">
        <svg
          className="user-icon-svg"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4
             1.79-4 4 1.79 4 4 4zm0 2c-2.67
             0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
        </svg>
      </div>
      {menuOpen && (
        <div className="menu-dropdown">
          {user ? (
            <>
              <div className="username">{user.username}</div>
              <Link to="/my-comics">My Comics</Link>
              <button onClick={() => setShowChangePassword(true)}>Change Password</button>
              <button onClick={logout}>Logout</button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  if (onSignUpClick) {
                    onSignUpClick();
                  } else {
                    console.error('onSignUpClick is undefined');
                  }
                }}
              >
                Sign Up
              </button>
              <button
                onClick={() => {
                  if (onLoginClick) {
                    onLoginClick();
                  } else {
                    console.error('onLoginClick is undefined');
                  }
                }}
              >
                Login
              </button>
            </>
          )}
        </div>
      )}
      {showChangePassword && (
        <ChangePasswordOverlay onClose={() => setShowChangePassword(false)} />
      )}
    </div>
  );
}

export default AccountMenu; 