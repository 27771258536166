import React from 'react';
import './LoginPromptOverlay.css';

function LoginPromptOverlay({ reason, onClose, onLogin, onSignup }) {
  const message = reason === 'like' 
    ? 'Please login to like comics' 
    : 'Please login to use Flux Pro';

  return (
    <div 
      className="overlay"
      onClick={() => {
        //console.log('Overlay clicked (outside)');
        onClose();
      }}
    >
      <div 
        className="overlay-content"
        onClick={(e) => {
          e.stopPropagation();
          //console.log('Overlay content clicked');
        }}
      >
        <p>{message}</p>

        <div style={{ marginTop: '1rem' }}>
          <a
            href="#"
            style={{ marginRight: '1rem' }}
            onClick={(e) => {
              e.preventDefault();
              //console.log('Login link clicked in LoginPromptOverlay');
              if (!onLogin) {
                console.error('onLogin is undefined');
                return;
              }
              onClose();
              onLogin();
            }}
          >
            Login
          </a>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              //console.log('Signup link clicked in LoginPromptOverlay');
              if (!onSignup) {
                console.error('onSignup is undefined');
                return;
              }
              onClose();
              onSignup();
            }}
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPromptOverlay; 