import React, { useState, useEffect } from 'react';
import './NovelDisplay.css';

const API_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5002';

const latinLanguages = [
  'english',
  'danish',
  'dutch',
  'fijian',
  'filipino',
  'french',
  'german',
  'hausa',
  'igbo',
  'indonesian',
  'italian',
  'norwegian',
  'polish',
  'portuguese',
  'spanish',
  'swahili',
  'swedish',
  'tok pisin',
  'turkish',
  'zulu'
];

// Helper function to choose font based on user-selected language
function getFontFamilyForLanguage(language = '') {
  const lang = language.toLowerCase();

  if (latinLanguages.includes(lang)) {
    return "'ReadyforAnythingBB', Arial, sans-serif";
  }
  if (lang === 'chinese') return "'NotoSansSC', sans-serif";
  if (lang === 'japanese') return "'NotoSansJP', sans-serif";
  if (lang === 'korean') return "'NotoSansKR', sans-serif";
  // Fallback font:
  return "'NotoSans', sans-serif";
}

const NovelDisplay = ({ novel, isLoading, expectedPanelCount }) => {
  const [loadedImages, setLoadedImages] = useState(new Set());
  const totalImages = novel?.content?.length || 0;

  const getPanelCountWarning = () => {
    if (!expectedPanelCount || !totalImages || expectedPanelCount === totalImages) return null;
    return `Received ${totalImages} panels ${
      expectedPanelCount > totalImages ? 'instead of' : 'rather than'
    } the expected ${expectedPanelCount}`;
  };

  if (isLoading) {
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress-bar-fill"></div>
        </div>
        <p>Generating your story... This may take a minute.</p>
      </div>
    );
  }

  if (!novel || !novel.content) {
    return null;
  }

  const warningMessage = getPanelCountWarning();  
  const chosenFontFamily = getFontFamilyForLanguage(novel.language);


  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          fontFamily: chosenFontFamily
        }}
      >
        {novel.title}
      </h2>
      {warningMessage && (
        <div
          style={{
            textAlign: 'center',
            color: '#856404',
            backgroundColor: '#fff3cd',
            padding: '0.75rem',
            marginBottom: '1rem',
            borderRadius: '0.25rem'
          }}
        >
          {warningMessage}
        </div>
      )}
      <div
        className="novel-display"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(min(250px, 100%), 1fr))',
          gap: '1rem',
          padding: '1rem',
          maxWidth: '1200px',
          margin: '0 auto'
        }}
      >
        {novel.content.map((panel, index) => (
          <div
            key={index}
            className="panel"
            style={{
              backgroundColor: '#f0f0f0',
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'auto'
            }}
          >
            {/* Image container */}
            <div
              style={{
                flex: '0 0 auto',
                position: 'relative',
                padding: '20px',
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={
                  panel.imageUrl.startsWith('http')
                    ? panel.imageUrl
                    : `${API_URL}${panel.imageUrl}`
                }
                alt={`Panel ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  display: 'block',
                  margin: 0,
                  padding: 0,
                  border: 'none'
                }}
                onError={(e) => {
                  console.error(`Failed to load image: ${panel.imageUrl}`);
                  e.target.style.display = 'none';
                }}
                onLoad={() => setLoadedImages((prev) => new Set([...prev, index]))}
              />
            </div>
            {/* Text container */}
            <div
              style={{
                padding: '10px',
                color: '#000000',
                textAlign: 'center',
                marginTop: '0px',
                borderTop: 'none',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                fontSize: '17px',
                height: 'auto',
                fontFamily: chosenFontFamily
              }}
            >
              {panel.dialogue || ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NovelDisplay; 