import React from 'react';
import './FAQ.css';

const FAQ = () => {
  return (
    <div className="faq-container">

      <div className="faq-item">
        <h2>What is Comic LLM?</h2>
        <p>Comic LLM is an AI-powered tool that creates visual stories in various artistic styles.</p>

        <h2>How does it work?</h2>
        <p>When you submit a story idea, we first use an LLM to create a structured narrative with descriptions, characters, and dialogue. Then, we generate images for each panel based on those descriptions.</p>

        <h2>Does it support other languages?</h2>
        <p>Yes. In your prompt specify the language you want. For example, "Create a comic in Japanese about a happy dog". You can also write this in your language. We should support most languages. If your language doesnt work, let us know. </p>
        
        <h2>Creating an account</h2>
        <p>Creating an account is optional. If you create an account, you can view and manage your comics in the My Comics tab. You can make your comics private, delete them, and edit the prompt.</p>

        <h2>Creating an Instagram carousel (a story with multiple panels)</h2>
        <p>To create an Instagram carousel, you can download the panels by clicking on Download Panels after generating the comic, or from your My Comics tab. The comics will be downloaded as a zip file. Double click on the zip file to create a directory containing the individual panels as separate image files.  Then upload them to an Instagram story to create a carousel.</p>

        <h2>Why is there weird text, and why do the people sometimes look different</h2>
        <p>With the image models we are using we do not have full control over the images being generated. However we are working on improving this. </p>

        <h2>Why did my comic generation fail?</h2>
        <p>Story generation might fail for content policy violations or technical issues with the AI services. If you encounter an error, try rephrasing your story idea or try again later.</p>

        <h2>Contact Us</h2>
        <p>support@comicllm.com</p>
        <p>Chat with us on <a href="https://discord.gg/FUVrn9TBGK" target="_blank" rel="noopener noreferrer">Discord</a></p>

      </div>
    </div>
  );
};

export default FAQ; 