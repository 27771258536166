import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from '../contexts/AuthContext';
import api from '../services/api';
import './ChangePasswordOverlay.css';

function ChangePasswordOverlay({ onClose }) {
  const { token } = useContext(AuthContext);  // or however you handle auth
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, onClose]);

  const handleChangePassword = async () => {
    try {
      setError(null);
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        setError('All fields are required.');
        return;
      }
      if (newPassword !== confirmNewPassword) {
        setError('New passwords do not match.');
        return;
      }

      const response = await api.post(
        '/api/change-password',
        {
          oldPassword,
          newPassword,
          confirmNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );

      const result = response.data;
      if (response.status !== 200) {
        throw new Error(result.error || 'Error changing password.');
      }

      setSuccessMessage('Password changed successfully.');
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError(err.message);
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="overlay">
      <div className="overlay-content">
        <h2>Change Password</h2>
        <p></p>
        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <form className="overlay-form">
          <label>Old Password:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />

          <label>Confirm New Password:</label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />

          <div className="button-footer-container">
            <button className="submit-button" type="button" onClick={handleChangePassword}>
              Change Password
            </button>
            <span className="switch-form-button" onClick={onClose}>
              Cancel
            </span>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
}

export default ChangePasswordOverlay; 