import React, { useState } from 'react';
import api from '../services/api';

function SignupForm() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/register', formData);
      setMessage('Signup successful. Please log in.');
    } catch (error) {
      setMessage(error.response?.data?.error || 'Signup failed.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Form Fields */}
    </form>
  );
}

export default SignupForm; 