import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { OverlayContext } from './contexts/OverlayContext';
import StoryForm from './components/StoryForm';
import Gallery from './components/Gallery';
import NovelDisplay from './components/NovelDisplay';
import FAQ from './components/FAQ';
import MyComics from './components/MyComics';
import AccountMenu from './components/AccountMenu';
import SignUpOverlay from './components/SignUpOverlay';
import LoginForm from './components/LoginForm';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotificationBar from './components/NotificationBar';
import './App.css';
import api from './services/api';
import logo from './logo.png';
import ResetPassword from './components/ResetPassword';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import SignupForm from './components/SignupForm';
import EditComic from './components/EditComic';

function CreateStory() {
  const [novel, setNovel] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleNovelGenerated = (generatedNovel) => {
    //console.log('Novel generated:', generatedNovel);
    setNovel(generatedNovel);
  };

  return (
    <div>
      <StoryForm
        onNovelGenerated={handleNovelGenerated}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <NovelDisplay novel={novel} isLoading={isLoading} />
    </div>
  );
}

function App() {
  const { user } = useContext(AuthContext);
  const {
    isSignUpVisible,
    isLoginVisible,
    showSignUp,
    showLogin,
    closeOverlay,
  } = useContext(OverlayContext);

  const [isNotificationVisible, setIsNotificationVisible] = useState(true);
  const notificationBarRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsNotificationVisible(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);

  useEffect(() => {
    const appHeader = document.querySelector('.App-header');
    if (appHeader) {
      if (isNotificationVisible && notificationBarRef.current) {
        const notificationHeight = notificationBarRef.current.offsetHeight;
        appHeader.style.marginTop = `${notificationHeight}px`;
      } else {
        appHeader.style.marginTop = '0';
      }
    }
  }, [isNotificationVisible]);

  return (
    <>
      <GoogleAnalytics />
      {isNotificationVisible && <NotificationBar ref={notificationBarRef} />}
      <div className="App">
        <header className={`App-header ${isNotificationVisible ? 'with-notification' : ''}`}>
          <div className="header-container">
            <h1 className="title-font header-title">
              <NavLink
                to="/"
                className="App-link title-font"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <img src={logo} alt="ComicLLM Logo" style={{ height: '50px' }} />
              </NavLink>
            </h1>
            <div className="nav-account-container">
              <nav className="header-nav">
                <NavLink
                  to="/"
                  className="App-link"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                >
                  Create
                </NavLink>
                <NavLink
                  to="/gallery"
                  className="App-link"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                >
                  Gallery
                </NavLink>
                <NavLink
                  to="/my-comics"
                  className="App-link"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                >
                  My Comics
                </NavLink>
                <NavLink
                  to="/faq"
                  className="App-link"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? 'bold' : 'normal',
                  })}
                >
                  Help
                </NavLink>
              </nav>
              <AccountMenu onSignUpClick={showSignUp} onLoginClick={showLogin} />
            </div>
          </div>
        </header>
        <main className={`main-content ${isNotificationVisible ? 'with-notification' : ''}`}>
          <Routes>
            <Route path="/" element={<CreateStory />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/my-comics" element={<MyComics />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/edit-comic/:id" element={<EditComic />} />
            <Route path="/edit/:id" element={<EditComic />} />
            {/* Add other routes as needed */}
          </Routes>
        </main>
        <footer className="App-footer">
          Chat with us on Discord <a
            href="https://discord.gg/FUVrn9TBGK"
            className="App-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/discord-logo.jpg"
              alt="Join our Discord"
              style={{ height: '35px', verticalAlign: 'middle' }}
            />
          </a>
          <div className="footer-links">
            <NavLink to="/privacy-policy" className="App-link">Privacy Policy</NavLink>
            {' | '}
            <NavLink to="/terms-of-service" className="App-link">Terms of Service</NavLink>
          </div>
        </footer>
        <SignUpOverlay
          isVisible={isSignUpVisible}
          onClose={closeOverlay}
          onSwitchToLogin={showLogin}
        />
        <LoginForm
          isVisible={isLoginVisible}
          onClose={closeOverlay}
          onSwitchToSignUp={showSignUp}
        />
      </div>
    </>
  );
}

export default App; 