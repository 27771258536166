import React from 'react';
import './NotificationBar.css';

const NotificationBar = React.forwardRef((props, ref) => {
  return (
    <div className="notification-bar" ref={ref}>
      New: You can now edit the comic text in My Comics 
    </div>
  );
});

export default NotificationBar; 