import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import { AuthContext } from '../contexts/AuthContext';
import { OverlayContext } from '../contexts/OverlayContext';
import ImageOverlay from './ImageOverlay';
import ConfirmationOverlay from './ConfirmationOverlay';
import './MyComics.css';
import { useNavigate } from 'react-router-dom';

function MyComics() {
  const { user } = useContext(AuthContext);
  const { showSignUp, showLogin } = useContext(OverlayContext);
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [comicToDelete, setComicToDelete] = useState(null);
  const [downloadingComic, setDownloadingComic] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }

    const fetchMyComics = async () => {
      try {
        const response = await api.get('/api/my-comics');
        //console.log('Fetched comics:', response.data.images);

        if (Array.isArray(response.data.images)) {
          setComics(response.data.images);
        } else {
          setComics([]);
          setError('Failed to load your comics.');
          console.error('Invalid API response:', response.data);
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to load your comics');
        setLoading(false);
        setComics([]);
        console.error('API error:', err);
      }
    };

    fetchMyComics();
  }, [user]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
  };

  const handleDeleteClick = (comicId) => {
    setComicToDelete(comicId);
    //console.log('Delete clicked for comic:', comicId);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/api/comics/${comicToDelete}`);
      setComics(comics.filter((comic) => comic.id !== comicToDelete));
      setComicToDelete(null);
    } catch (err) {
      console.error('Failed to delete comic:', err);
      alert('Failed to delete comic. Please try again.');
    }
  };

  const handleCancelDelete = () => {
    setComicToDelete(null);
  };

  const handlePrivacyChange = async (comicId, newPrivacyStatus) => {
    try {
      await api.put(`/api/comics/${comicId}`, { isPrivate: newPrivacyStatus });
      setComics(
        comics.map((comic) =>
          comic.id === comicId ? { ...comic, isPrivate: newPrivacyStatus } : comic
        )
      );
    } catch (err) {
      console.error('Failed to update privacy status:', err);
      alert('Failed to update privacy status. Please try again.');
    }
  };

  const handleDownloadPanels = (comicId) => {
    setDownloadingComic(comicId);
    api.get(`/api/comics/${comicId}/download-panels`, { responseType: 'blob' })
      .then((response) => {
        setDownloadingComic(null);
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'comic.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setDownloadingComic(null);
        console.error('Failed to download panels:', error);
        alert('Failed to download panels.');
      });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!user)
    return (
      <div className="my-comics-message">
        Please{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            showSignUp();
          }}
        >
          sign up
        </a>{' '}
        or{' '}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            showLogin();
          }}
        >
          log in
        </a>{' '}
        to manage your own comics.
      </div>
    );

  return (
    <div className="my-comics-container">
      <h3>Welcome {user?.username}</h3>
      <div className="comics-list">
        {Array.isArray(comics) && comics.map((comic) => (
          <div key={comic.id} className="comic-row">
            <div
              className="comic-thumbnail"
              onClick={() => handleImageClick(comic.imageWithTextUrl || comic.url)}
            >
              <img 
                src={comic.thumbnailUrl || comic.url} 
                alt={comic.filename} 
              />
            </div>
            <div className="comic-details">
              <p>
                <strong>Title:</strong> {comic.filename}
              </p>
              <p>
                <strong>Created:</strong>{' '}
                {new Date(comic.created).toLocaleDateString()}
              </p>
              <p>
                <strong>Private:</strong>
                <select
                  value={comic.isPrivate ? 'Yes' : 'No'}
                  onChange={(e) =>
                    handlePrivacyChange(comic.id, e.target.value === 'Yes')
                  }
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </p>
              <p>
                <strong>Prompt:</strong>
              </p>
              <textarea
                value={comic.prompt}
                readOnly
                className="prompt-textarea"
              />
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }}>
                {comic.hasPanelWithText && (
                  downloadingComic === comic.id ? (
                    <span>Preparing Download…</span>
                  ) : (
                    <button className="my-button" onClick={() => handleDownloadPanels(comic.id)}>
                      Download Panels
                    </button>
                  )
                )}
                <button className="my-button" onClick={() => handleDeleteClick(comic.id)}>
                  Delete
                </button>
                <button
                  className="my-button"
                  onClick={() => navigate(`/edit/${comic.id}`)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedImage && (
        <ImageOverlay 
          imageUrl={selectedImage}
          onClose={handleCloseOverlay} 
        />
      )}
      {comicToDelete && (
        <ConfirmationOverlay
          message="Are you sure you want to delete this comic? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
}

export default MyComics; 