import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import ImageOverlay from './ImageOverlay';
import './Gallery.css';
import { AuthContext } from '../contexts/AuthContext';
import LoginPromptOverlay from './LoginPromptOverlay';
import LoginForm from './LoginForm';
import SignUpOverlay from './SignUpOverlay';

function GalleryItem({ comic, onImageClick, onLikeToggle }) {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <div
      className="gallery-item"
      style={{ visibility: loaded ? 'visible' : 'hidden' }}
      onClick={() => onImageClick(comic.url)}
    >
      <span className="poster-name">{comic.userName}</span>
      <img
        src={comic.thumbnailUrl || comic.url}
        alt={comic.filename}
        onLoad={() => setLoaded(true)}
      />
      <div className="like-container" onClick={(e) => e.stopPropagation()}>
        <img
          className="like-icon"
          src={comic.upvotes > 0 ? '/liked.png' : '/unliked.png'}
          alt={comic.alreadyLiked ? 'Liked' : 'Unliked'}
          onClick={() => onLikeToggle(comic._id)}
        />
        {comic.upvotes > 0 && (
          <span className="like-count">
            {comic.upvotes} {comic.upvotes === 1 ? 'like' : 'likes'}
          </span>
        )}
      </div>
    </div>
  );
}

function Gallery({
  onSignUpClick,
  onLoginClick
}) {
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { user } = useContext(AuthContext);
  const isLoggedIn = Boolean(user);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpOverlay, setShowSignUpOverlay] = useState(false);

  useEffect(() => {
    const fetchGallery = async () => {
      // Clear comics before fetching new data
      setComics([]);
      setLoading(true);
      try {
        const response = await api.get(`/api/gallery?page=${currentPage}&limit=20`);
        setComics(response.data.images);
        setTotalPages(Math.ceil(response.data.totalImages / 20));
        setLoading(false);
      } catch (err) {
        setError('Failed to load gallery');
        setLoading(false);
      }
    };

    fetchGallery();
  }, [currentPage]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handleLikeToggle = async (comicId) => {
    if (!isLoggedIn) {
      setShowLoginPrompt({ show: true, reason: 'like' });
      return;
    }
    try {
      const response = await api.post(`/api/comics/${comicId}/like`);
      // The server responds with { success, numLikes }
      setComics((prev) =>
        prev.map((c) => {
          if (c._id === comicId) {
            return {
              ...c,
              upvotes: response.data.numLikes,
              alreadyLiked: !c.alreadyLiked
            };
          }
          return c;
        })
      );
    } catch (err) {
      console.error('Failed to toggle like:', err);
    }
  };

  const handleCloseLoginPrompt = () => {
    setShowLoginPrompt(false);
  };

  const handleLogin = () => {
    //console.log('Gallery: handleLogin triggered');
    setShowLoginPrompt(false);
    setShowLoginForm(true);
    //console.log('showLoginForm set to true');
  };

  const handleSignup = () => {
    //console.log('Gallery: handleSignup triggered');
    setShowLoginPrompt(false);
    setShowSignUpOverlay(true);
  };

  const handleSwitchToSignUp = () => {
    //console.log('Switching to SignUp form');
    setShowLoginForm(false);
    setShowSignUpOverlay(true);
  };

  const handleSwitchToLogin = () => {
    //console.log('Switching to Login form');
    setShowSignUpOverlay(false);
    setShowLoginForm(true);
  };

  if (error) return <div>{error}</div>;

  return (
    <div className="gallery-container">

      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div className="gallery-grid">
            {comics.map((comic, index) => (
              <GalleryItem
                key={index}
                comic={comic}
                onImageClick={handleImageClick}
                onLikeToggle={handleLikeToggle}
              />
            ))}
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      )}
      {selectedImage && (
        <ImageOverlay imageUrl={selectedImage} onClose={handleCloseOverlay} />
      )}
      {showLoginPrompt && (
        <LoginPromptOverlay
          reason={showLoginPrompt.reason}
          onClose={handleCloseLoginPrompt}
          onLogin={handleLogin}
          onSignup={handleSignup}
        />
      )}

      {showLoginForm && (
        <LoginForm
          isVisible={showLoginForm}
          onClose={() => setShowLoginForm(false)}
          onSwitchToSignUp={handleSwitchToSignUp}
        />
      )}
      <SignUpOverlay
        isVisible={showSignUpOverlay}
        onClose={() => setShowSignUpOverlay(false)}
        onSwitchToLogin={handleSwitchToLogin}
      />
    </div>
  );
}

export default Gallery; 